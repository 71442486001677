/* ========== Login ======== */
.login {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(180, 201, 226, 0.13);
  height: 100vh;
  min-height: 100vh;
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    .jalso__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;
    }
  }
  .login-box {
    background-color: white;
    box-shadow: 0 0 40px rgba(154, 154, 154, 0.16);
    padding: 50px;
    border-radius: 12px;
    min-width: 490px;
    height: 400px;
    margin-left: 20px;
    .title {
      text-align: center;
      font-size: 30px;
    }
  }
}

@media (max-width: 950px) {
  .left__img {
    display: none !important;
  }
  .login .login-box {
    min-width: 280px;
    margin-left: 0;
  }
}

/* ========== Header ======== */
.header {
  background-color: white;
  height: 76px;
  box-shadow: 0 0.5rem 1rem 0 rgb(44 51 73 / 10%);
  z-index: 9;
  position: sticky;
  top: 0;
}
.vertical-line-style {
  border: 1px solid !important;
  height: 25px;
  margin: 0 15px;
}
.ant-divider-horizontal {
  margin: 3px 0 !important;
}
.ant-dropdown {
  position: fixed !important;
  width: 200px;
}
.ant-dropdown-menu-item:hover {
  background-color: #faf0ff;
}

/* ========== Sidebar ======== */
.ant-layout-sider-children {
  height: 80vh !important;
  margin-top: 8px;
}
.ant-menu-vertical {
  border: 0;
}
.ant-layout-sider-trigger {
  margin-left: -4px !important;
}
.ant-menu-item-selected .ant-menu-title-content .title {
  font-weight: bold;
}
.ant-menu-title-content .title {
  font-weight: 500;
}

/* ========== Layout ======== */
.ant-layout-content {
  padding: 20px;
  background: #edf1f7 !important;
  overflow: auto;
}
.ant-menu-title-content {
  display: flex;
  align-items: center;
}

/* ========== Song ======== */
.songs .ant-input-affix-wrapper {
  width: 60% !important;
  font-size: 16px !important;
  background: #edf1f7 !important;
  border-radius: 5px !important;
}
.song-popup {
  width: 400px !important;
}
.ant-upload.ant-upload-drag {
  height: 200px !important;
  background: #fff !important;
  border: 3px dashed #000 !important;
}

/* ========== Dashboard ======== */
.gutter-box {
  padding: 8px 0;
  background: #00a0e9;
}
.trending-search {
  height: 418px;
  .trending-search-btn {
    background-color: #fff;
    color: #8f9bb3;
    border: 1px solid #8f9bb3;
    border-radius: 25px;
    font-size: 16px;
    padding: 4px 16px;
    font-weight: 500;
    margin-bottom: 9px;
    margin-right: 10px;
  }
}
.total-media {
  height: 400px;
}
.total-user {
  height: 393px;
}
.user-download {
  height: 400px;
}
.trending-this-months {
  min-height: 213px;
}

/* ========== Section ======== */
.section-empty-screen {
  margin-top: 125px;
}
.css-6j8wv5-Input input {
  width: 0;
  height: 0;
}

/* ========== Reports ======== */
.ant-tabs-top > .ant-tabs-nav:before {
  border-bottom: 0 !important;
}
